.selectedbreadcrumb {
  float: left;

  .label {
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
    display: inline-block;
  }

  ol {
    display: inline-flex;
    background: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 12px;
    color: $c_BahamaBlue;

    + li::before {
      padding: 0 5px;
      color: $c_LimedSpruce;
      content: '/';
    }
  }

  a {
    color: $c_BahamaBlue;
  }

  .icon {
    width: 12px;
    height: 12px;
  }
}
