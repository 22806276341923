.branding {
  .inner {
    line-height: $headerInnerHeight;
    padding: 0 20px;
    box-shadow: 0 2px 8px 0 rgba($c_Black, 0.2);
  }

  a {
    text-decoration: none;
  }

  &__text {
    font-weight: bold;

    &--small {
      display: none;
    }

    &--large {
      display: block;
    }
  }

  .sidebar-toggle {
    background-color: unset;
    border: unset;
    padding: 0;
    margin-left: auto;

    svg {
      color: $c_White;
      width: 20px;
      height: 20px;
    }

    .icon-menu {
      display: none;
    }
  }

  .toggle-navigation {
    display: flex;
  }
}
