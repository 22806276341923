.navbar-right {
  li {
    display: flex;
    align-items: center;
    border-left: 1px solid $c_Iron;
  }

  em {
    font-style: normal;
  }
}
