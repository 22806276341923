@import '../../bosch/scss/colors';

// *** Rexroth Colors ***

// primary
$c_Muesli: #002b49;
// secondary
$c_Mako: #506e81;

$c_ShuttleGray: #606265;
$c_RollingStone: #808285;

$c_Lima: #00c8f7;
$c_Lima_w60: tint($c_Lima, 60%);

$c_Iron: #e2e2e3;
$c_Monza: #e40421;

// Hint, Btn color
$c_darkblue: #002b49;
