.list {
  margin: 0;
  padding: 0 10px;
  font-size: 10px;

  li {
    list-style-type: disc;

    &:not(:last-child) {
      padding-bottom: 5px;
    }
  }
}
