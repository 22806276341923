@import './dataTable--paginate';

$control-button-size: 32px !default;

@mixin control() {
  display: block;
  color: $c_Black;
  border: 1px solid $c_FrenchGray;
  border-radius: 2px;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  line-height: $control-button-size;
}

@mixin control-open() {
  content: '+';
}

@mixin control-close() {
  content: '-';
}

.dataTable {
  width: 100% !important;

  &.select td a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.white {
    td {
      background: $c_White;
    }
  }

  tr {
    border-bottom: 1px solid $c_FrenchGray;

    &:last-child {
      border-bottom: 0;
    }

    &.even td,
    &.even + tr.child > td.child {
      @extend %stripesBg;
    }

    &.bordered {
      border: 1px solid $warningColor !important;
    }

    &.warning {
      td {
        background: $c_Monza_w85 !important;
        color: $c_Monza !important;
        font-weight: 700;
      }
    }

    &.problem {
      td {
        background: $c_SelectYellow_w85 !important;
      }
    }

    &.info {
      td {
        background: $c_BahamaBlue_w15 !important;
      }
    }

    &.success {
      td {
        background: $c_Lima_w60 !important;
      }
    }
  }

  th,
  td {
    background-color: $c_Seashell;
    padding: 10px;
    height: 50px;
    border-right: 1px solid $c_FrenchGray;

    &:last-child {
      border-right: 0;
    }

    &.dataTables_empty {
      text-align: center;
    }
  }

  th {
    background-color: $c_Iron;
    text-align: left;
    background-repeat: no-repeat;
    height: 40px;
    white-space: nowrap;
  }

  .btn-transparent {
    color: $c_RollingStone;
  }

  &.itemImpostor {
    tbody tr,
    tbody tr.odd,
    tbody tr.even {
      border-bottom: 3px solid $c_Seashell;

      &.warning {
        td {
          background: $c_Monza_w85 !important;
          color: $c_Monza !important;
          font-weight: 700;
        }
      }

      td {
        padding: 10px 10px 10px 0;
        background: none !important;
        background-color: $c_White !important;
        border-right: 0;
        vertical-align: middle;

        .fakeBorder {
          border-left: 1px solid $c_Iron;
          padding: 15px 10px;

          &.clearfix {
            padding: 12px 10px 11px;
          }
        }

        &:first-child {
          .fakeBorder {
            border-left: 0;
          }
        }

        &:last-child {
          clear: both;

          .btn {
            float: left;
            padding: 0 10px;
          }

          .dropdown {
            width: 30px;
            display: inline-block;
            float: left;

            a {
              padding: 0 10px;
            }
          }
        }

        &.even,
        &.odd {
          background: none;
          background-color: $c_White;
        }
      }
    }
  }

  .sorting_asc {
    background-image: url('../img/sort-arrows.png');
    background-position: right 5px top -40px;
  }

  .sorting_desc {
    background-image: url('../img/sort-arrows.png');
    background-position: right 5px top -80px;
  }

  .sorting {
    background-image: url('../img/sort-arrows.png');
    background-position: right 5px top 0;
    padding-right: 30px;
  }

  td {
    vertical-align: top;

    &.borderless {
      padding: 0;
    }

    &.nowrap {
      white-space: nowrap;
    }
  }

  .dropdown {
    display: inline-block;
  }

  &__exportState {
    span {
      visibility: hidden;
    }

    &--1 {
      width: 100%;
      height: 100%;
      background-color: $c_SelectYellow;
    }

    &--2 {
      width: 100%;
      height: 100%;
      background-color: $c_Monza;
    }
  }

  // RESPONSIVE: Styling for the 'inline' type
  &.dtr-inline.collapsed > tbody {
    > tr {
      > td.child,
      > th.child,
      > td.dataTables_empty {
        cursor: default !important;

        &::before {
          display: none !important;
        }
      }
    }

    > tr[role='row'] > td:first-child,
    > tr[role='row'] > th:first-child {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;

      &::before {
        @include control;
        @include control-open;
        margin-right: 20px;
        width: $control-button-size;
        height: $control-button-size;
        font-size: 26px;
        font-weight: bold;
      }
    }

    > tr.parent {
      > td:first-child::before,
      > th:first-child::before {
        @include control-close;
      }
    }
  }

  // DataTables' `compact` styling
  &.dtr-inline.collapsed.compact > tbody {
    > tr > td:first-child,
    > tr > th:first-child {
      padding-left: 27px;

      &::before {
        top: 5px;
        left: 4px;
        height: 14px;
        width: 14px;
        line-height: 14px;
        text-indent: 3px;
      }
    }
  }

  // Styling for the `column` type
  &.dtr-column > tbody {
    > tr > td.control,
    > tr > th.control {
      position: relative;
      cursor: pointer;

      &::before {
        @include control;
        @include control-open;
        top: 50%;
        left: 50%;
        height: 16px;
        width: 16px;
        margin-top: -10px;
        margin-left: -10px;
      }
    }

    > tr.parent {
      td.control::before,
      th.control::before {
        @include control-close;
      }
    }
  }

  // Child row styling
  > tbody > tr.child {
    padding: 5px 10px;

    &:hover {
      background: transparent !important;
    }

    ul.dtr-details {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        border-bottom: 1px solid #efefef;
        padding: 5px 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    span.dtr-title {
      display: inline-block;
      min-width: 75px;
      font-weight: bold;
    }
  }

  &--white-cells {
    td {
      background-color: $c_White;
    }
  }

  &--small {
    th {
      font-weight: normal;
    }

    td {
      height: 40px;
    }

    .btn {
      margin: 0 5px;
      line-height: normal;
    }
  }

  &--no-border {
    th,
    tr,
    td {
      border: 0;
    }

    tr {
      border-bottom: 1px solid $c_Iron;
    }
  }

  a {
    color: var(--color-black);

    &:hover {
      color: $link-color;
    }
  }
}

.dt-buttons {
  display: none;
}

.dataTables {
  &_processing {
    position: absolute;
    top: 45px;
    width: 80px;
    height: 80px;
    z-index: 1;
    left: calc(50% - 80px);

    &::after {
      content: ' ';
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $primaryColor;
      border-color: $primaryColor transparent $primaryColor transparent;
      animation: dataTables_processing 1.2s linear infinite;
    }

    @keyframes dataTables_processing {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    &.dts_loading {
      display: none;
    }
  }
}
