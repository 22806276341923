.contentBox {
  background-color: $c_Seashell;
  padding: 20px 0;

  .row {
    margin: 0;
    padding: 0 !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  div[class^='col']:first-child {
    border-right: 1px solid $c_RollingStone;
  }

  p {
    margin: 0 0 5px 0;
  }

  div[class^='col'] {
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .link {
    display: block;
    margin-bottom: 5px;
    width: 100%;
  }
}
