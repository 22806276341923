// === BOOTSTRAP Variables ===
$text-color: $c_Mako !default;
$primaryColor: $c_Muesli;
$input-color-placeholder: $c_GrayChateau !default;
$nav-link-hover-bg: $primaryColor !default;
$link-color: $primaryColor !default;
$link-hover-color: shade($primaryColor, 20%) !default;
$nav-tabs-link-hover-border-color: transparent;

// === Global ===
$body_bg: url('../img/bg_stripe_pattern.png') !default;
//  @extend %stripesBg;

// === Buttons ===
$btn_primary_fg: $c_White !default;
$btn_primary_bg: $primaryColor !default;
$btn_primary_bd: $primaryColor !default;
$btn_primary_ac: shade($primaryColor, 10%) !default;

$btn_secondary_fg: $c_White !default;
$btn_secondary_bg: $c_Mako !default;
$btn_secondary_bd: $c_Mako !default;
$btn_secondary_ac: shade($c_Mako, 10%) !default;

$btn_tertiary_fg: $c_White !default;
$btn_tertiary_bg: $c_Lima !default;
$btn_tertiary_bd: $c_Lima !default;
$btn_tertiary_ac: shade($c_Lima, 10%) !default;

// === Breadcrumb ===
$breadcrumb_active_fg: $primaryColor;
$breadcrumb_hover_fg: $primaryColor;
$breadcrumb_link_fg: $primaryColor;

// === Header ===
$header_top_bg: linear-gradient(0deg, $c_Seashell 45%, $c_White 100%) !default;
$header_supergraphic: none !default;

// === Flashmessage ===
$flashmessage_info: $c_darkblue;
$flashmessage_success: $c_Lima;
$flashmessage_danger: $c_Monza;
$flashmessage_warning: $c_Monza;

// === Dropdown Dark ===
$dropdown_dark_fg: $c_White !default;
$dropdown_dark_bg: $c_Mako !default;
$dropdown_dark_ac: $primaryColor !default;

// === Autocomplete token ===
$autocomplete_token_bg: $primaryColor;

// === Gallery Element ===
$galleryelement_new: $primaryColor !default;
$galleryelement_bg: $primaryColor !default;

// === Dropzone ===
$dropzone_fg: $primaryColor;
$dropzone_bg: tint($primaryColor, 85%);

// === Input Selected ===
$input_selected_fg: $c_Lima;
$input_selected_bg: $c_Lima_w60;
$input_check_bg: $primaryColor;

// Icon Options
$icon_fg: $c_RollingStone;
$icon_active_fg: $primaryColor;

// === Manuel ===
$manual_hover_bg: $primaryColor !default;

// === Boxed whatever ===
$boxed_bg: $c_Mako !default;

// === Login Page ===
$login_fg: $c_White !default;
$login_ln: $c_White !default;
$login_bg: url('../img/login_bg.jpg') !default;
$login_top_bd: 0;

// === Branding ===
$branding_bd: $c_White !default;
$branding_fg: $c_White !default;
$branding_bg: $c_Mako !default;

// === Brands selector ===
$brands_fg: $c_White;
$brands_bg: $c_Muesli;

// === Sidebar Left ===
$sidebar_left_bg: $c_Mako !default;

// === Sidebar Right ===
$sidebar_right_bd: $c_White !default;

// === Navigation ===
$navigation_item_bd: shade($c_Mako, 50%);
// $navigation_item_bg: $c_Mako !default;
$navigation_item_bg_hover: $primaryColor !default;
$navigation_item_bg_active: $primaryColor !default;

$navigation_item_subnav_bg: shade($c_Mako, 30%) !default;
// $navigation_item_subnav_bg_hover: rgba($c_Black, .3) !default;
$navigation_item_subnav_bg_active: shade($c_Mako, 30%) !default;

// === Gradients ===
$g_lr--primary: linear-gradient(90deg, rgba($primaryColor, 1), rgba($primaryColor, 0.75));
