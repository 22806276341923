.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  left: 0;
  padding-top: 50px;
  background-image: linear-gradient(transparent, #282725); //$c_Dune
  color: $c_FrenchGray;

  a {
    color: $c_FrenchGray;

    & + a {
      padding-left: 5px;

      &::before {
        content: '|';
        display: inline-block;
        width: 10px;
      }
    }
  }

  div + div {
    text-align: right;
  }
}
