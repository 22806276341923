.deSelectAll {
  &__link {
    cursor: pointer;
    font-weight: 700;
    margin-left: 15px;
  }

  .icon-arrow-right {
    width: 10px;
    height: 10px;
  }
}
