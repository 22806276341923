.manual {
  display: block;
  text-decoration: none;
  color: $c_Mako;
  border-radius: 2px;
  margin-bottom: 20px;

  .outterwrapper {
    background: $c_White;
    padding: 5px;
    border-radius: 2px;

    &:first-child {
      margin-bottom: 2px;
    }
  }

  &:hover {
    text-decoration: none;

    .outterwrapper {
      background: $c_BahamaBlue_w15;
      color: $c_White;
    }
  }

  .description {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .wrapper {
    height: 100%;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    &.gradient {
      background: linear-gradient($c_Iron, $c_White);
    }
  }
}
