.optionField {
  .no-padding-right {
    padding-right: 0 !important;
  }
}

.input-text {
  position: relative;
  border-collapse: separate;
}

.share-input {
  margin-bottom: 10px;
  margin-top: 10px;
}
