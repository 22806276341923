.sidebar-right {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  width: $sidebar-right-width;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: $c_Iron;

  &__header {
    border-top: 10px solid $c_FrenchGray;

    .inner {
      height: $headerInnerHeight;
      line-height: $headerInnerHeight;
      box-shadow: $header-box-shadow;
      padding: 0 20px;

      .icon {
        width: 22.5px;
        height: 22.5px;
        vertical-align: middle;
      }
    }
  }

  h4 {
    font-size: 14px;
    border-bottom: 1px solid $c_FrenchGray;
    line-height: 25px;
    padding: 7.5px 0;
    margin-bottom: 10px;

    &.required::after {
      content: '*';
      color: $c_Monza;
      margin-left: 5px;
    }
  }

  &__body {
    padding: 20px;
    border-top: 1px solid $c_White;

    .js-overviewButton {
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    padding: 20px;
    margin-top: auto;

    .btn {
      flex: 1;
      margin-right: 5px;

      &:nth-child(2) {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }

  .sidebar-subheader {
    color: rgba($c_Mako, 0.5);
    border-bottom: 1px solid $c_FrenchGray;
  }
}

.sidebar-right-open {
  .sidebar-right {
    display: flex;
    flex-direction: column;
  }

  .main-content-wrapper {
    margin-right: $sidebar-right-width;
    width: auto;
  }

  .sidebar-left + .main-content-wrapper #header {
    width: calc(100% - #{$sidebar-left-width} - #{$sidebar-right-width});
  }

  &.sidebar-mini-1 .sidebar-left + .main-content-wrapper #header {
    width: calc(100% - #{$sidebar-left-mini-width} - #{$sidebar-right-width});
  }
}
