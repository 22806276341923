.btn {
  font-weight: normal;
  line-height: 24px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  svg {
    pointer-events: none;
    vertical-align: middle;
  }

  &-white {
    background-color: $c_White;
    padding: 0 12px 0 0;
  }

  &-trans {
    background-color: transparent;
    padding: 0 12px 0 0;
  }

  &.active {
    box-shadow: none;
  }

  &.btn-blue-transparent {
    background: transparent;
    border: 1px solid $c_BahamaBlue !important;
    color: $c_BahamaBlue !important;
  }

  &.btn-blue {
    background: $c_BahamaBlue;
    border: 1px solid $c_BahamaBlue !important;
    color: $c_White !important;
  }

  &.btn-grey-transparent {
    background: transparent;
    border: 1px solid $c_Mako !important;
    color: $c_Mako !important;

    svg {
      fill: $c_Mako !important;
    }
  }

  &.btn-red-transparent {
    background: transparent;
    border: 1px solid $c_Monza !important;
    color: $c_Monza !important;

    svg {
      fill: $c_Monza !important;
    }
  }

  &.btn-center {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}
