.accordion {
  .bordered-bottom {
    border-bottom: 1px solid $c_FrenchGray;
    padding: 20px 0;

    &:last-child {
      border: 0;
    }
  }

  .panel {
    margin-bottom: 5px;

    .panel-body {
      padding-top: 0;

      .row {
        padding: 16px 0 16px 0;
      }

      .row:first-child {
        padding-top: 0;
      }

      h2 {
        margin-bottom: 20px;
      }
    }

    > .panel-heading {
      font-weight: 400;
      padding: 16px 0 16px 0;
      text-transform: none !important;

      + .panel-collapse > .panel-body {
        border-top: medium none;
      }
    }
  }

  .table {
    td:nth-child(2) p:not(:last-child) {
      margin: 5px 0;
      width: fit-content;
      padding-right: 5px;
    }

    td:nth-child(2) p:last-child {
      margin: 5px 0;
    }
  }

  &-toggle.link a {
    color: $c_Mako;
  }

  a {
    text-decoration: none !important;

    &:hover,
    &:active {
      text-decoration: none !important;
    }

    .pull-right {
      .icon {
        transition: transform 0.5s ease;
      }
    }

    &.collapsed {
      color: $link-color;

      .pull-right {
        .icon {
          transform: rotate(-90deg);
          transition: transform 0.5s ease;
        }
      }

      .icon {
        fill: $link-color;
      }

      &:hover,
      &:active,
      &:focus {
        color: $link-hover-color;

        .icon {
          fill: $link-hover-color;
        }
      }
    }
  }
}
