.formGroup {
  border-top: 1px solid $c_FrenchGray;
  padding: 10px 0;
  margin: 0;

  .date-icon {
    display: block;
    width: 32px;
    height: 37px;
    position: absolute;
    top: 10px;
    left: 15px;
  }

  [type='date'],
  input.date-text {
    padding: 0 0 0 30px;
  }

  input + input,
  textarea + textarea {
    margin-top: 10px;
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }

  &-tag {
    padding: 5px 10px 4px;
    background-color: $c_Iron;
    border-radius: 15px;
    margin-right: 10px;
    font-size: 12px;
    color: $c_Mako !important;
    cursor: pointer;

    &:hover {
      background-color: $c_FrenchGray;
    }
  }

  &-tag-no-hover {
    &:hover {
      cursor: default;
      background-color: $c_Iron;
    }
  }
}
