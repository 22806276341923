.intro {
  margin: 30px 0;

  &__cta {
    margin-left: 20px;

    &--right {
      margin-left: auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }
}
