.tabs {
  margin-bottom: 30px;

  .nav-tabs {
    border-bottom: none;

    > li {
      margin-bottom: 0;

      > a {
        padding: 5px 20px;
        line-height: 30px;
        color: $text-color;

        &:focus {
          background-color: $c_White;
        }
      }

      &.active {
        > a {
          background-color: $c_White;
        }
      }

      &:not(:active):not(.active) {
        > a {
          background-color: $c_Iron;

          &:hover {
            background-color: $c_White;
          }
        }
      }
    }
  }

  .tab-content {
    background-color: $c_White;
    padding: 20px;

    .boxed-white {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      min-height: 59px;
      max-height: 59px;

      .link {
        margin-bottom: -7px;
        margin-right: 10px;

        &:first-child {
          margin-left: 2px;
        }
      }
    }
  }

  &.transparent {
    .tab-content {
      background-color: transparent;
      padding: 0;

      #tab_download {
        padding: 10px;
        background-color: $c_White;
      }

      .row {
        margin: 0;
      }
    }
  }

  &.transparent_all {
    .tab-content {
      background-color: transparent;
      padding: 0;

      .tab-pane {
        padding: 0;
        background-color: transparent;
      }
    }
  }

  &.solid-bar {
    .nav-tabs {
      background-color: $c_LimedSpruce_b15;
      color: $c_White;

      > li {
        > a {
          padding: 5px 20px;
          color: $c_White;
          background-color: $c_LimedSpruce_b15;
          line-height: 30px;

          svg {
            fill: $c_White !important;
          }
        }

        &.active {
          > a {
            background-color: $c_BahamaBlue_w15;
          }
        }
      }
    }

    .tab-content {
      background-color: transparent;
      padding: 20px 0 0 0;
    }
  }
}
