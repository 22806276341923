.langSelector {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px;

  &__flag {
    width: 24px;
    margin-left: 5px;
    margin-right: 10px;
  }

  thead::after {
    content: '-';
    display: block;
    line-height: 10px;
    color: transparent;
  }

  th {
    background-color: $c_Iron;
    line-height: 30px;
    padding: 10px;
  }

  td {
    background-color: $c_White;
    margin-bottom: 1px;
    padding: 5px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    min-width: 33.33%;
    padding: 5px;
  }

  &__link {
    display: block;
    color: $c_Mako;
    background-color: $c_Seashell;
    line-height: 30px;
    padding: 0 10px;
    margin: 5px;

    &:hover {
      color: $c_White;
      background-color: $c_BahamaBlue_b15;
    }

    &--selected {
      color: $c_White;
      background-color: $c_BahamaBlue;
    }
  }
}
