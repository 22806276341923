.js-filterByID {
  * {
    pointer-events: none;
  }

  &.active {
    .checkbox-custom-label::before {
      color: $c_Black;
      content: '\2714'; //fat check-mark
      font-size: 12px;
      line-height: 15px;
    }
  }
}
