.selectedOptions {
  margin: 10px 0 30px 0;
  display: table;

  &.affix {
    background-color: $c_Seashell;
    top: 70px;
    box-shadow: $header-box-shadow;
    z-index: 198;
    margin-left: -30px;
    display: block;
    padding: 15px;
    width: 100%;
  }

  p {
    margin: 0 15px 0 0;
    font-weight: 700;
    font-size: 15px;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin: 0 10px 0 0;
      display: inline-block;
      font-weight: normal;
      font-size: 15px;
      cursor: pointer;

      .icon {
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
    }
  }
}
