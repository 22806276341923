.profileBox {
  background-color: $c_White;
  color: $c_Mako !important;
  padding: 20px;

  &__info {
    text-align: center;
    margin-bottom: 40px;
  }

  .row--bordered {
    border-top: 1px solid $c_FrenchGray;

    .col-xs-12 {
      padding: 15px 10px;

      p {
        margin: 0;
      }
    }
  }

  &__langSwitch {
    display: flex;
    align-items: center;

    .selectCountry {
      margin-left: auto;
    }
  }
}
