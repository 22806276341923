.galleryelement {
  position: relative;
  width: 100%;
  background: $c_White;
  margin: 5px;
  border: 1px solid transparent;

  @media (min-width: $screen-sm-min) {
    width: calc(50% - 10px);
  }

  @media (min-width: $screen-md-min) {
    width: calc(33.33% - 10px);
  }

  @media (min-width: $screen-lg-min) {
    width: calc(25% - 10px);
  }

  &.lightgrey {
    background: $c_Iron;
  }

  &__next {
    visibility: hidden;
  }

  .header {
    border-bottom: 1px solid $c_Iron;
    padding: 4px 10px;

    // Cut text to ellipsis
    .col-xs-8 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-top: 4px;
    }
  }

  .fileicon.big {
    margin-bottom: auto !important;
  }

  .content {
    padding: 10px;
  }

  .new {
    display: inline-block;
    padding: 8px;
    background: $g_lr--primary;
    color: $c_White;
    width: auto;
    white-space: nowrap;
    position: absolute;
    margin-top: 5px;
  }

  .ftr {
    display: inline-block;
    margin-top: 10px;
  }

  .icn {
    display: inline-block;
    width: auto;
    width: 40px;
  }

  .formGroup-tag {
    width: fit-content;
    text-transform: uppercase;
    color: $c_Black;
    margin-bottom: 7.5px;

    &:hover {
      background: $c_Iron;
    }
  }

  .formGroup-tag-no-hover {
    &:hover {
      cursor: default;
    }
  }

  .wrapper {
    height: 230px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.gradient {
      background: linear-gradient($c_Seashell, $c_White);
    }

    img {
      display: block;
      max-height: 230px;
      flex-shrink: 0; // IE11 Bugfix
    }

    &:hover {
      .hovercontent {
        display: block !important;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: left;
        background: rgba($c_BahamaBlue, 0.85);
        padding: 16px 16px;
        min-height: 50px;
        color: $c_White;
        position: absolute;

        h3 {
          color: $c_White;
          margin: 0;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .videPlayButton {
    z-index: 3;
    position: absolute;
    width: 15%;
  }

  .icon-play-button {
    width: 50px;
    fill: rgba($c_Black, 0.8);
  }

  &:hover,
  &.active {
    border-color: $c_Mako;
  }

  &.big {
    .content {
      p,
      h3 {
        margin: 0;
        color: $c_Mako;
        overflow-wrap: break-word;
      }

      .link {
        margin-top: 10px;
        display: block;
      }

      p.description {
        color: $c_GrayChateau;
      }

      .wrapper {
        height: 150px;

        img {
          max-height: 150px;
        }
      }
    }
  }

  .btn-transparent {
    float: right;
    padding: 0 0 0 12px;
    color: $c_RollingStone;

    &:hover,
    &.active,
    &:active {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .dropdown {
    width: 30px;
    display: inline-block;
    float: right;
  }

  .dropdown-menu {
    height: 155px;
    overflow: hidden;
    overflow-y: auto;
    top: 25px;
    width: 200px;
    position: absolute;
    margin-left: -160px;
    z-index: 99;

    a {
      line-height: 2.2;
    }
  }

  &--no-margin {
    margin: 0;
  }

  .btn-transparent {
    &.active {
      svg {
        fill: $c_BahamaBlue_w15;
      }
    }
  }
}
