@import './bootstrap-select';

/* stylelint-disable selector-no-qualifying-type */
select.select {
  // Always hide selcetbox, avoid FOUC when bootstrap-select initializing
  display: none !important;
}
/* stylelint-enable selector-no-qualifying-type */

.select {
  .bs-searchbox,
  .bs-actionsbox,
  .bs-donebutton {
    padding: 2px 10px;
  }

  .dropdown-menu {
    min-width: fit-content;

    li {
      a {
        &.opt {
          padding-left: 15px;
        }
      }
    }

    > li {
      padding: 0;

      > a {
        padding: 6px 15px;
      }
    }

    .inner {
      max-height: 200px;
      overflow-x: hidden;
    }

    .dropdown-header {
      &:hover {
        background-color: inherit;
      }

      span {
        display: block;
        padding: 6px 15px;
      }
    }
  }

  &.show-tick {
    .dropdown-menu {
      .selected {
        .check-mark {
          right: unset;
          right: 0;
          top: 15px;

          &::after {
            content: '✔';
            font-size: 16px;
          }
        }
      }
    }
  }
}
