// Variables, Mixins
@import 'includes/mixins';
@import './colors';
@import 'variables';
@import './colormap';
@import 'includes/bootstrap-custom';

@import 'global';

// Include Components
// @import '../components/**/*.scss';

// Include only selected components for mediapool!
@import 'mediapool';

// Theme - Map Colors to css selectors
.theme-rexroth {
  @import 'theme';

  .main-content-wrapper {
    justify-content: center;
  }

  .login {
    &__leftRight {
      display: flex;
      margin-top: 20px;

      a {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &__intern {
      border-right: 1px solid #002b49;
      padding-right: 20px;
    }

    &__extern {
      padding-left: 20px;
    }
  }

  .login-form {
    max-width: 705px;
    background-color: $c_Iron;
    color: #002b49;
    padding: 20px;
  }

  .header-simple {
    box-shadow: 0 15px 30px 0 rgba(90, 124, 145, 0.35);
  }
}
