#header {
  position: fixed;
  z-index: 200;
  right: 0;
  top: 0;
  min-height: $headerHeight - 6px;

  .inner {
    min-height: $headerInnerHeight;
    box-shadow: 0 2px 8px 0 rgba($c_Black, 0.2);
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
  }

  @if $header_supergraphic == 'none' {
  } @else {
    &::before {
      display: table;
      content: '';
      background-position: 0 -100px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 6px;
      width: 100%;
    }
  }

  .logo img {
    margin-right: 6px;
    margin-top: -4px;
  }

  .logo span {
    font-weight: 700;
  }

  .navbar-nav {
    margin: 0;
  }

  .btn i {
    padding: 0;
  }

  .nav {
    a.dropdown-toggle,
    > li > a,
    > li > button {
      cursor: pointer;
      color: $c_Mako;
      color: $c_Black;
      font-size: 12px;
      padding: 15px 20px;
      line-height: 30px;
      background-color: transparent;
      border-radius: 0;
      border: none;

      &:hover,
      &:focus {
        color: $c_Black;
        background-color: transparent;
        outline: 0;

        svg {
          fill: $c_Black;
        }
      }
    }

    .dropdown-menu > li {
      padding: 0;

      > a {
        padding: 12px 20px;
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      fill: $c_Mako;
      vertical-align: middle;
    }

    &:hover,
    &:active {
      box-shadow: none;
      outline: none !important;
      border: none;
      color: $c_Black;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  .search {
    appearance: none !important;
    color: #282323;
    outline: 0;
    height: 30px;
    width: 180px;
    padding: 7px 15px;
    font-size: 12px;
    font-weight: normal;
    vertical-align: top;
    background-color: #edf1f2;
    box-shadow: none;
    border-radius: 30px;
    border: none;
    transition: background 0.2s linear 0s, box-shadow 0.2s linear 0s;
    margin: 16px 0 0 20px;
    position: relative;
  }

  .btn-search {
    position: absolute;
    top: 17px;
    right: 5px;
    background: none;
  }

  .nav li.profile > a > .meta {
    display: table;
    width: 100%;
  }

  .nav li.profile > a > .meta > .avatar {
    display: table-cell;
    vertical-align: middle;
    max-width: 32px;
    height: 32px;
  }

  .nav li.profile > a > .meta > .text {
    font-size: 12px;
    font-weight: 600;
    padding: 0 5px;
  }

  .nav li.profile > a {
    padding: 0 15px;
    height: 60px;
    line-height: 60px;
    color: #13161c;
    text-shadow: none;
  }

  .nav li.profile > a:hover,
  .nav li.profile > a:active,
  .nav li.profile > a:focus {
    box-shadow: none;
    outline: none !important;
    border: none;
    color: #13161c;
  }

  .arrow {
    border-width: 8px;
    z-index: 10;
  }

  .arrow,
  .arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .arrow::after {
    border-width: 7px;
    content: '';
  }

  .arrow.top {
    left: 50%;
    margin-left: -8px;
    border-top-width: 0;
    border-bottom-color: #eee;
    border-bottom-color: rgba($c_Black, 0.1);
    top: -8px;
  }

  .arrow.top::after {
    content: ' ';
    top: 1px;
    margin-left: -7px;
    border-top-width: 0;
    border-bottom-color: $c_White;
  }

  .dropdown-menu > li > a > .icon {
    display: inline-block;
    min-width: 14px;
    text-align: center;
    margin-right: 5px;
  }

  .dropdown-menu > li.active > a,
  .dropdown-menu > li:active > a {
    background-color: #fbfbfb;
    color: #444;
  }

  .dropdown-menu > li.active > a:hover,
  .dropdown-menu > li:active > a:hover {
    background-color: #fbfbfb;
    color: #373737;
  }

  .profile ul > li:first-child {
    padding: 10px;
  }

  .profile ul > li h5,
  .profile ul > li p {
    font-size: 13px;
    margin: 0;
    padding: 0;
  }

  .profile ul > li .progress {
    margin-bottom: 0;
  }
}

.sidebar-left + form .main-content-wrapper #header,
.sidebar-left + .main-content-wrapper #header {
  left: #{$sidebar-left-width};
}

.sidebar-mini-1 form .main-content-wrapper #header,
.sidebar-mini-1 .main-content-wrapper #header {
  left: #{$sidebar-left-mini-width};
}

.sidebar-right-open {
  #header {
    right: $sidebar-right-width;
  }
}

@media (max-width: 789px) {
  #header .search {
    width: 155px;
  }
}

@media (max-width: $screen-xs-max) {
  #header .logo {
    font-size: 22px;
    padding: 14px 0 0 0;
  }

  #header .logo img {
    width: 25px;
    margin-right: 5px;
  }

  #header .navbar-left {
    position: absolute;
    left: 0;
  }

  #header .navbar-right {
    position: absolute;
    right: 0;
  }
}

@media only screen and (max-width: 660px) {
  #header .user-nav ul {
    padding-left: 0;
  }

  // #header .toggle-navigation.toggle-left {
  //   float: left;
  // }

  .user-nav ul li {
    font-size: 12px;
  }
}

@media only screen and (max-width: 479px) {
  #header .dropdown.messages {
    display: none;
  }
}
