.navbar-left {
  color: $c_Black;
  display: flex;
  align-items: center;

  .toggle-left {
    border-right: 1px solid $c_Iron;
  }

  &__logo {
    padding: 15px;
    height: 60px;
  }
}
