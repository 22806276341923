%stripesBg {
  background: $stripesBg;
}

.icon {
  width: 15px;
  height: 15px;
  fill: currentColor;
}

.link {
  color: $link-color;

  &:hover {
    color: $link-color;
  }

  .icon {
    width: 9px;
    height: 9px;
    fill: currentColor;
  }

  span {
    color: $c_Mako;

    .icon {
      fill: $c_Mako;
    }
  }
}

.img {
  &--has-border {
    border: 1px solid $c_Seashell;
  }
}

.width-65perc {
  width: 65%;
}

.width-50perc {
  width: 50%;
}

.width-100perc {
  width: 100%;
}

.default-color {
  color: $defaultColor !important;
  fill: $defaultColor !important;
}

.primary-color {
  color: $primaryColor !important;
  fill: $primaryColor !important;
}

.success-color {
  color: $successColor !important;
  fill: $successColor !important;
}

.info-color {
  color: $infoColor !important;
  fill: $infoColor !important;
}

.warning-color,
.required {
  color: $warningColor !important;
  fill: $warningColor !important;
}

.default-bg-color {
  background-color: $defaultColor !important;
}

.primary-bg-color {
  background-color: $primaryColor !important;
}

.success-bg-color {
  background-color: $successColor !important;
}

.info-bg-color {
  background-color: $infoColor !important;
}

.warning-bg-color {
  background-color: $warningColor !important;
}

.padding-none {
  padding: 0 !important;
}

.margin-none {
  margin: 0;
}

.block {
  display: block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-left-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-10 {
  margin-right: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.min-width-128 {
  min-width: 128px !important;
}

.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.truncate-block {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  float: left;
}

// A Style for a row
.well {
  background-color: $c_Seashell;
  border-bottom: 1px solid $c_RollingStone;
  padding: 15px;
  margin-bottom: 20px;

  &.transparent {
    background-color: transparent;
    border: 0;
  }

  &.border-btm {
    border-bottom: 1px solid $c_Iron;
  }
}

.table {
  border-top: 1px solid $c_Iron;
  width: 100%;

  &__avatar {
    width: 30px;
  }

  &__avatar img {
    border-radius: 50%;
  }

  tr {
    border-bottom: 1px solid $c_Iron;

    td,
    th {
      padding: 10px;
      background-color: $c_White;

      &:nth-child(even) {
        background-color: $c_Seashell;
      }

      p:first-child {
        margin-top: 5px;
      }

      p:only-child {
        margin: 5px 0;
      }
    }

    th {
      background-color: $c_Seashell;
      text-align: left;

      &:nth-child(even) {
        background-color: $c_Iron;
      }
    }
  }

  &.regular {
    tr {
      border-bottom: 1px solid $c_FrenchGray;

      &:hover {
        td {
          background-color: $c_White;

          &:nth-child(even) {
            background-color: $c_White;
          }
        }
      }

      td,
      th {
        border-right: 1px solid $c_FrenchGray;
        padding: 10px;
        background-color: $c_Seashell;

        &:nth-child(even) {
          background-color: $c_Seashell;
        }

        &:last-child {
          border-right: 0;
        }
      }

      th {
        background-color: $c_Iron;
        text-align: left;

        &:nth-child(even) {
          background-color: $c_Iron;
        }
      }
    }
  }
}

.table.plain {
  td {
    &:nth-child(even) {
      background-color: unset;
    }
  }

  tr th:nth-child(even) {
    background-color: $c_Seashell;
  }

  td:nth-child(odd) {
    width: 30%;
  }
}

h2 + .table {
  margin-top: 20px;
}

.striped {
  .row {
    &:nth-child(odd) {
      background-color: $c_Seashell;
    }
  }
}

.boxed-white {
  background-color: $c_White;
  padding: 20px;

  .headline {
    border-bottom: 1px solid $c_Iron;
    padding: 0 0 10px 0;
    margin-bottom: 15px;
  }
}

.boxed-light-grey {
  background-color: $c_Seashell;
  padding: 20px !important;
}

.boxed-dark {
  background-color: $c_LimedSpruce_b15;
  padding: 5px;
}

@for $i from 35 through 235 {
  .img-maxheight-#{$i} {
    max-height: 0px + $i !important;
  }

  .img-wrapper-#{$i} {
    height: 0px + $i !important;
    text-align: center;
    display: inline-block;
    width: 0px + $i * 1.5;
    float: left;
  }
}

.ratio {
  &--16-9 {
    @include aspect-ratio(16, 9);
    max-height: 45px;

    img {
      max-height: 100%;
    }

    .content {
      margin: 0 auto;
    }
  }
}
